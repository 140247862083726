import React from "react";
import { Link } from "react-router-dom";

export default function ArticleCard(props) {
  return (
    <Link to={"/articles/" + props.article._id} className="article d-grid">
      <div className="older-posts-article-image-wrapper">
        <img
          src={"/images/thumbnails/" + props.article.thumbnailUrl}
          alt=""
          className="article-image"
        />
      </div>
      <span className="article-category-left">{props.article.category}</span>

      <div className="article-data-container">
        <div className="article-data">
          <span>
            {new Date(props.article.datePublished).toLocaleDateString("en-US", {
              day: "numeric",
              month: "long",
              year: "numeric",
            })}
          </span>
          <span className="article-data-spacer"></span>
          <span>{props.article.readTime} min read</span>
        </div>

        <h3 className="title article-title">{props.article.title}</h3>
        <p className="article-description">
          {props.article.markdown.substring(0, 160) + "..."}
        </p>
      </div>
    </Link>
  );
}
