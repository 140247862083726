import React from "react";
import ProfilePicture from "../../assets/images/profile_pic.jpeg";
import "./About.css";

export default function About() {
  return (
    <section className="about-page-container">
      <div className="about-page-row">
        <h1 className="about-page-title">Editors-in-Chief</h1>
      </div>
      <div className="about-page-row">
        <div className="column-3-items">
          <div className="about-page-card">
            <div className="img-container">
              <img src={ProfilePicture} alt="Profile" />
            </div>
            <h3>Simba Shi</h3>
            <p>Editor-in-Chief</p>
            <div className="about-page-icons">
              <a href="#">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#">
                <i className="fab fa-linkedin"></i>
              </a>
              <a href="#">
                <i className="fab fa-github"></i>
              </a>
              <a href="#">
                <i className="fas fa-envelope"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="column-3-items">
          <div className="about-page-card">
            <div className="img-container">
              <img src={ProfilePicture} alt="Profile" />
            </div>
            <h3>Ralph Matta</h3>
            <p>Editor-in-Chief</p>
            <div className="about-page-icons">
              <a href="#">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#">
                <i className="fab fa-linkedin"></i>
              </a>
              <a href="#">
                <i className="fab fa-github"></i>
              </a>
              <a href="#">
                <i className="fas fa-envelope"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="column-3-items">
          <div className="about-page-card">
            <div className="img-container">
              <img src={ProfilePicture} alt="Profile" />
            </div>
            <h3>Christopher Whitfeld</h3>
            <p>Editor-in-Chief</p>
            <div className="about-page-icons">
              <a href="#">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#">
                <i className="fab fa-linkedin"></i>
              </a>
              <a href="#">
                <i className="fab fa-github"></i>
              </a>
              <a href="#">
                <i className="fas fa-envelope"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="about-page-row">
        <h1 className="about-page-title">Editors</h1>
      </div>
      <div className="about-page-row">
        <div className="column-3-items">
          <div className="about-page-card">
            <div className="img-container">
              <img src={ProfilePicture} alt="Profile" />
            </div>
            <h3>Joshua Kim</h3>
            <p>Editor</p>
            <div className="about-page-icons">
              <a href="#">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#">
                <i className="fab fa-linkedin"></i>
              </a>
              <a href="#">
                <i className="fab fa-github"></i>
              </a>
              <a href="#">
                <i className="fas fa-envelope"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="column-3-items">
          <div className="about-page-card">
            <div className="img-container">
              <img src={ProfilePicture} alt="Profile" />
            </div>
            <h3>Koza Kurumlu</h3>
            <p>Editor</p>
            <div className="about-page-icons">
              <a href="#">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#">
                <i className="fab fa-linkedin"></i>
              </a>
              <a href="#">
                <i className="fab fa-github"></i>
              </a>
              <a href="#">
                <i className="fas fa-envelope"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="column-3-items">
          <div className="about-page-card">
            <div className="img-container">
              <img src={ProfilePicture} alt="Profile" />
            </div>
            <h3>Orlando White</h3>
            <p>Editor</p>
            <div className="about-page-icons">
              <a href="#">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#">
                <i className="fab fa-linkedin"></i>
              </a>
              <a href="#">
                <i className="fab fa-github"></i>
              </a>
              <a href="#">
                <i className="fas fa-envelope"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="column-3-items">
          <div className="about-page-card">
            <div className="img-container">
              <img src={ProfilePicture} alt="Profile" />
            </div>
            <h3>Matthew Lin</h3>
            <p>Editor</p>
            <div className="about-page-icons">
              <a href="#">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#">
                <i className="fab fa-linkedin"></i>
              </a>
              <a href="#">
                <i className="fab fa-github"></i>
              </a>
              <a href="#">
                <i className="fas fa-envelope"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="column-3-items">
          <div className="about-page-card">
            <div className="img-container">
              <img src={ProfilePicture} alt="Profile" />
            </div>
            <h3>Eric Ayivor</h3>
            <p>Editor</p>
            <div className="about-page-icons">
              <a href="#">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#">
                <i className="fab fa-linkedin"></i>
              </a>
              <a href="#">
                <i className="fab fa-github"></i>
              </a>
              <a href="#">
                <i className="fas fa-envelope"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="column-3-items">
          <div className="about-page-card">
            <div className="img-container">
              <img src={ProfilePicture} alt="Profile" />
            </div>
            <h3>Morgan He</h3>
            <p>Editor</p>
            <div className="about-page-icons">
              <a href="#">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#">
                <i className="fab fa-linkedin"></i>
              </a>
              <a href="#">
                <i className="fab fa-github"></i>
              </a>
              <a href="#">
                <i className="fas fa-envelope"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="column-3-items">
          <div className="about-page-card">
            <div className="img-container">
              <img src={ProfilePicture} alt="Profile" />
            </div>
            <h3>Ned Van Steenis</h3>
            <p>Editor</p>
            <div className="about-page-icons">
              <a href="#">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#">
                <i className="fab fa-linkedin"></i>
              </a>
              <a href="#">
                <i className="fab fa-github"></i>
              </a>
              <a href="#">
                <i className="fas fa-envelope"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="column-3-items">
          <div className="about-page-card">
            <div className="img-container">
              <img src={ProfilePicture} alt="Profile" />
            </div>
            <h3>Sediq Hamayun</h3>
            <p>Editor</p>
            <div className="about-page-icons">
              <a href="#">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#">
                <i className="fab fa-linkedin"></i>
              </a>
              <a href="#">
                <i className="fab fa-github"></i>
              </a>
              <a href="#">
                <i className="fas fa-envelope"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="column-3-items">
          <div className="about-page-card">
            <div className="img-container">
              <img src={ProfilePicture} alt="Profile" />
            </div>
            <h3>Gyu Bhin Kim</h3>
            <p>Editor</p>
            <div className="about-page-icons">
              <a href="#">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#">
                <i className="fab fa-linkedin"></i>
              </a>
              <a href="#">
                <i className="fab fa-github"></i>
              </a>
              <a href="#">
                <i className="fas fa-envelope"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="about-page-row">
        <h1 className="about-page-title">Designers</h1>
      </div>
      <div className="about-page-row">
        <div className="column-2-items">
          <div className="about-page-card">
            <div className="img-container">
              <img src={ProfilePicture} alt="Profile" />
            </div>
            <h3>Oliver Shaw</h3>
            <p>Designer-in-Chief</p>
            <div className="about-page-icons">
              <a href="#">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#">
                <i className="fab fa-linkedin"></i>
              </a>
              <a href="#">
                <i className="fab fa-github"></i>
              </a>
              <a href="#">
                <i className="fas fa-envelope"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="column-2-items">
          <div className="about-page-card">
            <div className="img-container">
              <img src={ProfilePicture} alt="Profile" />
            </div>
            <h3>Matthew Lin</h3>
            <p>Designer</p>
            <div className="about-page-icons">
              <a href="#">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#">
                <i className="fab fa-linkedin"></i>
              </a>
              <a href="#">
                <i className="fab fa-github"></i>
              </a>
              <a href="#">
                <i className="fas fa-envelope"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="column-2-items">
          <div className="about-page-card">
            <div className="img-container">
              <img src={ProfilePicture} alt="Profile" />
            </div>
            <h3>Ahnaf Kabir</h3>
            <p>Designer</p>
            <div className="about-page-icons">
              <a href="#">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#">
                <i className="fab fa-linkedin"></i>
              </a>
              <a href="#">
                <i className="fab fa-github"></i>
              </a>
              <a href="#">
                <i className="fas fa-envelope"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="column-2-items">
          <div className="about-page-card">
            <div className="img-container">
              <img src={ProfilePicture} alt="Profile" />
            </div>
            <h3>Michael Adeboye</h3>
            <p>Designer</p>
            <div className="about-page-icons">
              <a href="#">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#">
                <i className="fab fa-linkedin"></i>
              </a>
              <a href="#">
                <i className="fab fa-github"></i>
              </a>
              <a href="#">
                <i className="fas fa-envelope"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
