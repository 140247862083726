import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export default function EditArticle() {
  const [article, setArticle] = useState(null);
  const params = useParams();

  // Retrieve article when the page first loads.
  useEffect(() => {
    async function fetchArticle() {
      const articleId = params.articleId.toString();
      const response = await fetch(
        `https://etonet-node-server.herokuapp.com/api/articles/${articleId}`
      );

      const article = await response.json();
      setArticle(article);
    }

    fetchArticle();
  }, [params.articleId]);

  return (
    <div className="container" style={{ marginTop: 100 }}>
      <h1>Edit Article</h1>

      <form
        action={
          "https://etonet-node-server.herokuapp.com/api/articles/" +
          params.articleId +
          "/edit"
        }
        method="POST"
      >
        <div>
          <label htmlFor="title">Title</label>
          <textarea
            required
            defaultValue={article && article.title}
            name="title"
          />
        </div>
        <div>
          <label htmlFor="markdown">Markdown</label>
          <textarea
            required
            defaultValue={article && article.markdown}
            name="markdown"
          />
        </div>
        <div>
          <label htmlFor="author">Author</label>
          <textarea
            required
            defaultValue={article && article.author}
            name="author"
          />
        </div>
        <div>
          <label htmlFor="datePublished">Date published</label>
          <textarea
            required
            defaultValue={article && article.datePublished}
            name="datePublished"
          />
        </div>
        <div>
          <label htmlFor="thumbnailUrl">Thumbnail URL</label>
          <textarea
            required
            defaultValue={article && article.thumbnailUrl}
            name="thumbnailUrl"
          />
        </div>
        <div>
          <label htmlFor="readTime">Read time</label>
          <textarea
            required
            defaultValue={article && article.readTime}
            name="readTime"
          />
        </div>
        <div>
          <label htmlFor="category">Category</label>
          <textarea
            required
            defaultValue={article && article.category}
            name="category"
          />
        </div>
        <div>
          <label htmlFor="manualHtml">Manual HTML</label>
          <textarea
            defaultValue={article && article.sanitizedHtml}
            name="manualHtml"
          />
        </div>
        <div>
          <label htmlFor="password">Password</label>
          <textarea required name="password" />
        </div>
        <div>
          <label htmlFor="shouldHtml">Should HTML</label>
          <textarea name="shouldHtml" />
        </div>

        <button type="submit">Save</button>
      </form>
    </div>
  );
}
