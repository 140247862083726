import React from "react";
import "./Editions.css";

export default function Editions() {
  return (
    <div className="editions-container">
      <h3>This page is coming soon!</h3>
    </div>
  );
}
