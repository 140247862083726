import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import About from "./components/About/About";
import Article from "./components/Articles/Article/Article";
import CreateArticle from "./components/Articles/CreateArticle";
import EditArticle from "./components/Articles/EditArticle";
import Editions from "./components/Articles/Editions/Editions";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./components/Home";
import Memes from "./components/Articles/Memes/Memes";
import Puzzles from "./components/Puzzles/Puzzles";
import ScrollToTop from "./helpers/ScrollToTop";

export default function App() {
  return (
    <Router>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/articles/:articleId" element={<Article />} />
        <Route path="/articles/:articleId/edit" element={<EditArticle />} />
        <Route path="/new" element={<CreateArticle />} />
        <Route path="/editions" element={<Editions />} />
        <Route path="/memes" element={<Memes />} />
        <Route path="/puzzles" element={<Puzzles />} />
      </Routes>
      <Footer />
    </Router>
  );
}
