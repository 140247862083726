import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

export default function Header() {
  // Store the user's preferred theme (light or dark) in JavaScript localStorage.
  const [theme, setTheme] = useState(
    localStorage.getItem("theme") || "light-theme"
  );

  // Set the colour-theme when page first loads.
  useEffect(() => {
    localStorage.setItem("theme", theme);
    document.body.className = theme;
  }, [theme]);

  // Button press function to change the colour-theme.
  function toggleTheme() {
    if (theme === "light-theme") {
      setTheme("dark-theme");
    } else {
      setTheme("light-theme");
    }
  }

  // Button press function to trigger mobile navigation menu.
  function toggleMenu() {
    const mobileMenu = document.getElementById("menu");
    const menuToggleIcon = document.getElementById("menu-toggle-icon");
    mobileMenu.classList.toggle("activated");
    menuToggleIcon.classList.toggle("activated");
  }

  return (
    <header className="header" id="header">
      <nav className="navbar container">
        <NavLink to="/">
          <h2 className="logo">Etonet</h2>
        </NavLink>

        <div className="menu" id="menu">
          <ul className="list">
            <li className="list-item">
              <NavLink to="/" className="list-link" activeclassname="active">
                Home
              </NavLink>
            </li>
            {/* <li className="list-item">
              <Link to="#" className="list-link">
                Topics
              </Link>
            </li> */}
            <li className="list-item">
              <NavLink
                to="/editions"
                className="list-link"
                activeclassname="active"
              >
                Editions
              </NavLink>
            </li>
            <li className="list-item">
              <NavLink
                to="/memes"
                className="list-link"
                activeclassname="active"
              >
                Memes
              </NavLink>
            </li>
            <li className="list-item">
              <NavLink
                to="/puzzles"
                className="list-link"
                activeclassname="active"
              >
                Puzzles
              </NavLink>
            </li>
            <li className="list-item">
              <NavLink
                to="/about"
                className="list-link"
                activeclassname="active"
              >
                About Us
              </NavLink>
            </li>
            {/* <li className="list-item screen-lg-hidden">
              <Link to="./signin.html" className="list-link">
                Sign in
              </Link>
            </li>
            <li className="list-item screen-lg-hidden">
              <Link to="./signup.html" className="list-link">
                Sign up
              </Link>
            </li> */}
          </ul>
        </div>

        <div className="list list-right">
          <button
            onClick={toggleTheme}
            className="btn place-items-center"
            id="theme-toggle-btn"
          >
            <i className="ri-sun-line sun-icon"></i>
            <i className="ri-moon-line moon-icon"></i>
          </button>

          {/* <button className="btn place-items-center" id="search-icon">
            <i className="ri-search-line"></i>
          </button> */}

          <button
            onClick={toggleMenu}
            className="btn place-items-center screen-lg-hidden menu-toggle-icon"
            id="menu-toggle-icon"
          >
            <i className="ri-menu-3-line open-menu-icon"></i>
            <i className="ri-close-line close-menu-icon"></i>
          </button>

          {/* <Link to="#" className="list-link screen-sm-hidden">
            Sign in
          </Link>
          <Link
            to="#"
            className="btn sign-up-btn fancy-border screen-sm-hidden"
          >
            <span>Sign up</span>
          </Link> */}
        </div>
      </nav>
    </header>
  );
}
