import React from "react";

export default function Footer() {
  return (
    <footer className="footer section">
      <div className="footer-container container d-grid">
        <div className="company-data">
          <h2 className="logo">Etonet</h2>
          <p className="company-description">
            The official Tech & Computer Science magazine of Eton College.
          </p>

          <span className="copyright-notice">
            &copy; 2024 Etonet. This website is a Simba Shi production.
          </span>

          {/* <ul className="list social-media">
            <li className="list-item">
              <a
                href="https://www.youtube.com/watch?v=xvFZjo5PgG0&ab_channel=Duran"
                className="list-link"
                target="_blank"
                rel="noreferrer"
              >
                <i className="ri-instagram-line"></i>
              </a>
            </li>
            <li className="list-item">
              <a
                href="https://www.youtube.com/watch?v=xvFZjo5PgG0&ab_channel=Duran"
                className="list-link"
                target="_blank"
                rel="noreferrer"
              >
                <i className="ri-facebook-circle-line"></i>
              </a>
            </li>
            <li className="list-item">
              <a
                href="https://www.youtube.com/watch?v=xvFZjo5PgG0&ab_channel=Duran"
                className="list-link"
                target="_blank"
                rel="noreferrer"
              >
                <i className="ri-twitter-line"></i>
              </a>
            </li>
            <li className="list-item">
              <a
                href="https://www.youtube.com/watch?v=xvFZjo5PgG0&ab_channel=Duran"
                className="list-link"
                target="_blank"
                rel="noreferrer"
              >
                <i className="ri-pinterest-line"></i>
              </a>
            </li>
          </ul> */}
        </div>

        {/* <div>
          <h6 className="title footer-title">Categories</h6>

          <ul className="footer-list list">
            <li className="list-item">
              <a href="#" className="list-link">
                Travel
              </a>
            </li>
            <li className="list-item">
              <a href="#" className="list-link">
                Food
              </a>
            </li>
            <li className="list-item">
              <a href="#" className="list-link">
                Technology
              </a>
            </li>
            <li className="list-item">
              <a href="#" className="list-link">
                Health
              </a>
            </li>
            <li className="list-item">
              <a href="#" className="list-link">
                Nature
              </a>
            </li>
            <li className="list-item">
              <a href="#" className="list-link">
                Fitness
              </a>
            </li>
          </ul>
        </div>

        <div>
          <h6 className="title footer-title">Useful links</h6>

          <ul className="footer-list list">
            <li className="list-item">
              <a href="#" className="list-link">
                Home
              </a>
            </li>
            <li className="list-item">
              <a href="#" className="list-link">
                Elements
              </a>
            </li>
            <li className="list-item">
              <a href="#" className="list-link">
                Tags
              </a>
            </li>
            <li className="list-item">
              <a href="#" className="list-link">
                Authors
              </a>
            </li>
            <li className="list-item">
              <a href="#" className="list-link">
                Membership
              </a>
            </li>
          </ul>
        </div>

        <div>
          <h6 className="title footer-title">Company</h6>

          <ul className="footer-list list">
            <li className="list-item">
              <a href="#" className="list-link">
                Contact us
              </a>
            </li>
            <li className="list-item">
              <a href="#" className="list-link">
                F.A.Q
              </a>
            </li>
            <li className="list-item">
              <a href="#" className="list-link">
                Careers
              </a>
            </li>
            <li className="list-item">
              <a href="#" className="list-link">
                Authors
              </a>
            </li>
            <li className="list-item">
              <a href="#" className="list-link">
                Memberships
              </a>
            </li>
          </ul>
        </div> */}
      </div>
    </footer>
  );
}
