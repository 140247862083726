import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export default function Article() {
  const [article, setArticle] = useState(null);
  const params = useParams();

  // Retrieve article when the page first loads.
  useEffect(() => {
    async function fetchArticle() {
      const articleId = params.articleId.toString();
      const response = await fetch(
        `https://etonet-node-server.herokuapp.com/api/articles/${articleId}`
      );

      const article = await response.json();
      setArticle(article);
    }

    fetchArticle();
  }, [params.articleId]);

  const articleHtml = article && article.sanitizedHtml;

  return (
    <section className="blog-post section-header-offset">
      <div className="blog-post-container container">
        <div className="blog-post-data">
          <h3 className="title blog-post-title">{article && article.title}</h3>
          <h4 className="blog-post-author">By {article && article.author}</h4>
          <div className="article-data">
            <span>
              {article &&
                new Date(article.datePublished).toLocaleDateString("en-US", {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                })}
            </span>
            <span className="article-data-spacer"></span>
            <span>{article && article.readTime} min read</span>
          </div>
          <img
            src={article && "/images/thumbnails/" + article.thumbnailUrl}
            alt=""
          />
        </div>

        <div className="container">
          <div dangerouslySetInnerHTML={{ __html: articleHtml }} />

          {/* <div className="author d-grid">
            <div className="author-image-box">
              <img
                src="./assets/images/author.jpg"
                alt=""
                className="article-image"
              />
            </div>
            <div className="author-about">
              <h3 className="author-name">John Doe</h3>
              <p>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eaque
                quis repellat rerum, possimus cumque dolor repellendus eligendi
                atque explicabo exercitationem id.
              </p>
              <ul className="list social-media">
                <li className="list-item">
                  <a href="#" className="list-link">
                    <i className="ri-instagram-line"></i>
                  </a>
                </li>
                <li className="list-item">
                  <a href="#" className="list-link">
                    <i className="ri-facebook-circle-line"></i>
                  </a>
                </li>
                <li className="list-item">
                  <a href="#" className="list-link">
                    <i className="ri-twitter-line"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
}
