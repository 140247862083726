import React from "react";

export default function CreateArticle() {
  return (
    <div className="container" style={{ marginTop: 100 }}>
      <h1>New Article</h1>

      <form
        action="https://etonet-node-server.herokuapp.com/api/articles/new"
        method="POST"
      >
        <div>
          <label htmlFor="title">Title</label>
          <textarea required name="title" />
        </div>
        <div>
          <label htmlFor="markdown">Markdown</label>
          <textarea required name="markdown"></textarea>
        </div>
        <div>
          <label htmlFor="author">Author</label>
          <textarea required name="author"></textarea>
        </div>
        <div>
          <label htmlFor="datePublished">Date published</label>
          <textarea name="datePublished" />
        </div>
        <div>
          <label htmlFor="thumbnailUrl">Thumbnail URL</label>
          <textarea name="thumbnailUrl" />
        </div>
        <div>
          <label htmlFor="readTime">Read time</label>
          <textarea name="readTime" />
        </div>
        <div>
          <label htmlFor="category">Category</label>
          <textarea name="category" />
        </div>
        <div>
          <label htmlFor="password">Password</label>
          <textarea required name="password" />
        </div>

        <button type="submit">Save</button>
      </form>
    </div>
  );
}
