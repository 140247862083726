import React from "react";
import ArticleCard from "../Article/ArticleCard";

export default function FeaturedArticles(props) {
  return (
    <section className="older-posts section" style={{ marginTop: 45 }}>
      <div className="container">
        <h2 className="title section-title" data-name="Older posts">
          {props.sectionTitle}
        </h2>

        <div className="older-posts-grid-wrapper d-grid">
          {props.articles
            ? props.articles.map((article) => (
                <ArticleCard key={article._id} article={article} />
              ))
            : "Loading articles... Please wait up to 5 seconds"}
        </div>
      </div>
    </section>
  );
}
