import React from "react";
import "./Memes.css";

export default function Memes() {
  const imageNumbers = [...Array(6).keys()];

  return (
    <div className="memes-container">
      {imageNumbers.map((number) => (
        <img
          className="meme-image"
          src={"/images/memes/" + number.toString() + ".png"}
          key={number}
          alt=""
        />
      ))}
    </div>
  );
}
